import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from './home';

export default ({ location }) => (
  <Layout>
    <SEO
      // FIXME: These should be updated when we "close down"
      title="Tomorrow - Data Driven Climate Action"
      description="Tomorrow builds tech that empowers people and organisations to understand and reduce their carbon footprint."
      keywords={['tomorrow', 'climate change', 'climate action', 'startup', 'electricity maps']}
      location={location}
    />
    <Home />
  </Layout>
);
