import React from 'react';
import styled from 'styled-components';

import theme from '../../utils/themeconstants';

const StyledSection = styled.div`
  padding: ${props => (props.padding ? '8rem 0' : 0)};
  margin: 0;
  margin-bottom: ${props => (2 * props.marginSize)}rem;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    margin-bottom: 2rem;
    padding: ${props => (props.padding ? '4rem 0' : 0)}
  }
`;

const StyleIntro = styled.div`
  margin-bottom: ${theme.space.lg * 2}px;
`;

const SectionTitle = styled.h2`
  font-weight: normal;
  text-align: center;
  margin-bottom: 1.25rem;
`;

const SectionLead = styled.h3`
  font-weight: lighter;
  text-align: center;
`;

const Section = ({
  children, title, lead, sub, id, style, padding,
}) => {
  const marginSize = padding ? 0 : sub ? 2 : 4;
  return (
    <StyledSection id={id} marginSize={marginSize} style={style} padding={padding}>
      {
        (title || lead) && (
          <StyleIntro>
            <SectionTitle>
              {title}
            </SectionTitle>
            <SectionLead>
              {lead}
            </SectionLead>
          </StyleIntro>
        )
      }
      {children}
    </StyledSection>
  );
};

export default Section;
