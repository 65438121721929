import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { Container as GridContainer, Row, Col } from 'react-grid-system';

import EmapLogoSource from '../../images/svg/electricitymap-logo-icon.svg';

import theme from '../../utils/themeconstants';
import ButtonWithLogo from '../../components/elements/buttonWithLogo';
import Button from '../../components/elements/button';
import Section from '../../components/elements/section';

const StyledBackgroundImage = styled(BackgroundImage)`
  &&:before {
    opacity: 0.75!important;
  }
`

const LeadText = styled.p`
  color: white;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: bold;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 0 0 0.25rem;
  @media (max-width: ${theme.dim.smallmobilebreakpoint}px) {
    font-size: 0.9rem;
    text-align: center;
  }

`;

const StyledTitle = styled.h1`
  color: white;
  font-size: 4.5rem;
  font-weight: bold;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  @media (max-width: ${theme.dim.mobilebreakpoint}px) {
    font-size: 2.5rem;
  }
  @media (max-width: ${theme.dim.smallmobilebreakpoint}px) {
    font-size: 2rem;
    margin-top: 2rem;
    text-align: center;
  }

`;

const Buttons = styled.div`
  display: flex;
  a:first-of-type {
    margin-right: 12px;
  }
  @media (max-width: ${theme.dim.smallmobilebreakpoint}px) {
    flex-direction: column;
    text-align: center;
    a:first-of-type {
      margin-right: 0px;
    }
  }

`;


const EmapLogo = styled(EmapLogoSource)`
  margin: 0 2px 0 6px;
  height: 20px;
`;


export default () => {
  const { headerImgMobile, headerImgDesktop } = useStaticQuery(
    graphql`
      query {
        headerImgMobile: file(relativePath: { eq: "header_img_mobile.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 768) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImgDesktop: file(relativePath: { eq: "header_img.png" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  // NOTE: This will only serve the image based on the screen size of the initial render
  // It won't update on resize, which is ok - at least for now.
  const imageSources = [
    headerImgMobile.childImageSharp.fluid,
    {
      ...headerImgDesktop.childImageSharp.fluid,
      media: `(min-width: ${theme.dim.mobilebreakpoint}px)`,
    },
  ];

  return (
    <>
      <StyledBackgroundImage
        Tag="section"
        id="media-test"
        fluid={imageSources}
        style={{
          backgroundColor:"#021434",
          backgroundSize: '100% 100%',
          height: '100vh',

        }}
      >
        <Section
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <GridContainer>
            <Row>
              <Col md={12} lg={8}>
                <StyledTitle>Tomorrow is now Electricity Maps</StyledTitle>
                <LeadText>
                  Since the end of 2021, Tomorrow has shifted it&apos;s focus to electricityMap.<br />
                  It is the same people, just with one shared mission:
                  to organise the world&apos;s electricity data to drive the transition
                  towards a truly decarbonised electricity system.
                </LeadText>
                <Buttons>
                    <ButtonWithLogo href="https://electricitymaps.com" color={theme.colors.red} style={{marginRight: 12}}>
                      Go to <EmapLogo /> electricitymaps.com
                    </ButtonWithLogo>
                    <Button to="/blog" color="black">
                      Tomorrow Blog
                    </Button>
                </Buttons>
              </Col>
            </Row>
          </GridContainer>
        </Section>
      </StyledBackgroundImage>
    </>
  );
};
